import React from 'react'
import styled from 'styled-components'
import { colors } from '../../tailwind'
import tw from 'tailwind.macro'
import { UpDown, UpDownWide } from '../styles/animations'
import SVG from '../components/SVG'

import { BigTitle, Subtitle, Content, Divider, ContentBackground } from './../custom-elements/custom-elements'

const Wrapper = styled.div`
  ${tw`w-full xl:w-2/3`};
`

const MenuBar = styled.data`
position : absolute;
display : flex;
top : 10px;
right : 20px;
`

const MenuBarItem = styled.h1`
${tw`lg:text-xl font-sans text-white`};
padding-left : 20px;
margin-right : 10px;
transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

&:hover {
    cursor : pointer;
    transform: translateY(-2px);
}
`

const GetStartedButton = styled.h1`
display : inline-block;
color : white;
padding : 5px 10px;

transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: translateY(-5px);
  }


${ContentBackground} {
    padding : 5px 20px;
    padding-right : 40px;
    border-radius : 2px;
    cursor : pointer;
    font-family : "Open Sans","-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","sans-serif";
}

`


const Intro = () => (
    <>



        <Divider speed={0.2} offset={0}>
            <UpDown>
                <SVG icon="triangle" hiddenMobile width={48} stroke={colors.orange} left="10%" top="20%" />
                <SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />
                <SVG icon="box" width={6} fill={colors['grey-darker']} left="60%" top="15%" />
            </UpDown>
            <UpDownWide>
                <SVG icon="arrowUp" hiddenMobile width={16} fill={colors['blue-dark']} left="80%" top="10%" />
                <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="50%" />
                <SVG icon="circle" width={16} fill={colors['grey-darker']} left="70%" top="90%" />
                <SVG icon="triangle" width={16} stroke={colors['grey-darkest']} left="30%" top="65%" />
                <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="75%" top="10%" />
                <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
            </UpDownWide>
            <SVG icon="circle" hiddenMobile width={24} fill={colors['grey-darker']} left="5%" top="70%" />
            <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="4%" top="20%" />
            <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="50%" top="60%" />
            <SVG icon="upDown" width={8} fill={colors['grey-darkest']} left="95%" top="90%" />
            <SVG icon="upDown" hiddenMobile width={24} fill={colors['grey-darker']} left="40%" top="80%" />
            <SVG icon="triangle" width={8} stroke={colors['grey-darker']} left="25%" top="5%" />
            <SVG icon="circle" width={64} fill={colors.green} left="95%" top="5%" />
            <SVG icon="box" hiddenMobile width={64} fill={colors.purple} left="5%" top="90%" />
            <SVG icon="box" width={6} fill={colors['grey-darkest']} left="10%" top="10%" />
            <SVG icon="box" width={12} fill={colors['grey-darkest']} left="40%" top="30%" />
            <SVG icon="hexa" width={16} stroke={colors['grey-darker']} left="10%" top="50%" />
            <SVG icon="hexa" width={8} stroke={colors['grey-darker']} left="80%" top="70%" />
        </Divider>




        <Content speed={0.4} offset={0}>
            <Wrapper>
                <BigTitle>Introducing Quark</BigTitle>
                <Subtitle>An extensible platform to build graphical user interfaces for your everyday projects.</Subtitle>
                <UpDown style={{ zIndex: 2000, display: 'inline-block', position: 'relative' }}>
                    <GetStartedButton>
                        <ContentBackground bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)" clipPath="polygon(0 0, calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%)">
                            Get Started
                        </ContentBackground>
                    </GetStartedButton>
                </UpDown>
            </Wrapper>
        </Content>



        <UpDown style={{ zIndex: 2000, display: 'inline-block', height: '100px' }}>
            <MenuBar>
                {/* <ContentBackground bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)" clipPath="polygon(0 50%, 20px 0, 100% 0, 100% 100%, 20px 100%, 0 50%)"> */}
                <ContentBackground bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" clipPath="polygon(0 50%, 20px 0, 100% 0, 100% 100%, 20px 100%, 0 50%)">
                    <MenuBarItem onClick={onClick}>GitHub</MenuBarItem>
                    <MenuBarItem>Docs</MenuBarItem>
                </ContentBackground>
            </MenuBar>
        </UpDown>
    </>
)


const onClick = () => {
    console.log('hello');
}

export default Intro
