import React from 'react'
import styled from 'styled-components'
import { colors } from '../../tailwind'
import tw from 'tailwind.macro'
import { UpDown, UpDownWide } from '../styles/animations'
import SVG from '../components/SVG'
import triangle from '../images/triangle.svg'
import { rotateAnimation } from '../styles/animations'

import { BigTitle, Subtitle, Title, Content, Divider, ContentBackground, Inner } from './../custom-elements/custom-elements'

const List = styled.h1`
${tw`text-2xl lg:text-2xl font-serif text-white mb-8 tracking-wide relative`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding-left : 20px;
  cursor : pointer;
  display : flex;
  justify-content : space-between;

  
&:before {
    content: ${props => props.content || ""};
    background: ${props => props.bg || 'transparent'};
    font-size : ${props => props.fontSize || '10px'};
    position: absolute;
    background-size: 40px;
    ${rotateAnimation('4s')};
    left: -5px;
    top: 8px;
  }
`

// const ListItem = styled(List)`
// ${tw`text-xl lg:text-xl font-serif text-white mb-8 tracking-wide relative`};

// transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
// margin-top : -2px;
// margin-bottom : -2px;

// &:hover {
//   transform: translateY(-2px);
// }
// `

const CardContainer = styled.div`
${tw`text-2xl lg:text-2xl font-serif text-white mb-8 tracking-wide relative`};
// ${tw`shadow-lg relative no-underline rounded-lg px-8 py-8  text-white`};
display : flex;
background: ${props => props.bg || 'transparent'};
justify-content : space-evenly;
border-radius : 2px;
padding : 50px 10px 10px 10px;

&:before {
    content: ${props => props.title || "'transparent'"};
    background: ${props => props.bg || 'transparent'};
    position: absolute;
    background-size: 40px;
    text-align : center;
    left: 0px;
    top: 8px;
  }

`

const Card = styled.div`
background : ${props => props.bg};
cursor : pointer;
flex-grow : 1;
margin : 0px 20px;
text-align : center;
${tw`shadow-lg relative no-underline rounded-lg px-8 py-8  text-white`};
transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
clip-path: ${props => props.clipPath};
  &:hover {
    transform: translateY(-5px);
  }
`



const QuickReferences = () => (
    <>



        {/* <Divider bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)" clipPath="polygon(0% 12%, 100% 0%, 100% 82%, 0% 100%)" speed={0.2} offset={1} /> */}
        <Divider bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)" clipPath="polygon(0% 12%, 100% 0%, 100% 82%, 0% 100%)" speed={0.2} offset={1} />
        <Divider speed={0.1} offset={1}>
            <UpDown>
                <SVG icon="box" hiddenMobile width={6} fill={colors.blue} left="50%" top="75%" />
                <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
                <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
                <SVG icon="upDown" hiddenMobile width={24} fill={colors.orange} left="80%" top="80%" />
            </UpDown>
            <UpDownWide>
                <SVG icon="arrowUp" hiddenMobile width={16} fill={colors.purple} left="5%" top="80%" />
                <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />
                <SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />
                <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
            </UpDownWide>
            <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
            <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
            <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
            <SVG icon="box" width={12} fill={colors['grey-darkest']} left="20%" top="30%" />
            <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
        </Divider>
        <Content speed={0.1} offset={1}>
            <Inner>
                <Title>Quick Links</Title>
                <CardContainer title="'Downloads'">
                    <Card bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)">Windows Installer (.msi)	</Card>
                    <Card bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)">macOS Installer (.pkg)	</Card>
                    <Card bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)">Linux Binaries (x64)	</Card>
                </CardContainer>
                <CardContainer title="'Usecase'">
                    <Card bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)">For Nodejs</Card>
                    <Card bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)">For Internet Of Things</Card>
                </CardContainer>

                <CardContainer title="'Links'">
                    <Card bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)">Github</Card>
                    <Card bg="linear-gradient(to right, #00b09b 0%, #96c93d 100%)">Documentation</Card>
                </CardContainer>
            </Inner>
        </Content>
    </>
)

export default QuickReferences
