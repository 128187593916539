import React from 'react'
import PropTypes from 'prop-types'
import { UpDown, UpDownWide } from '../styles/animations'
import { colors } from '../../tailwind'
import SVG from '../components/SVG'

import { BigTitle, Subtitle, Title, Content, Divider, DividerMiddle, ContentBackground, Inner } from './../custom-elements/custom-elements'

const Description = () => (
    <>
        <DividerMiddle
            // bg="#23262b"
            bg="rgb(255, 211, 153)"
            speed={0}
            offset={2}
            factor={2}
        />
        <Content speed={0.4} offset={2} factor={2}>
            <Inner>Hello</Inner>
        </Content>
        <Divider speed={0.1} offset={2} factor={2}>
            <UpDown>
                <SVG icon="box" width={6} fill={colors.white} left="85%" top="75%" />
                <SVG icon="upDown" width={8} fill={colors.teal} left="70%" top="20%" />
                <SVG icon="triangle" width={8} stroke={colors.orange} left="25%" top="5%" />
                <SVG icon="circle" hiddenMobile width={24} fill={colors.white} left="17%" top="60%" />
            </UpDown>
            <UpDownWide>
                <SVG icon="arrowUp" hiddenMobile width={16} fill={colors.green} left="20%" top="90%" />
                <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="30%" />
                <SVG icon="circle" width={16} fill={colors.yellow} left="70%" top="90%" />
                <SVG icon="triangle" hiddenMobile width={16} stroke={colors.teal} left="18%" top="75%" />
                <SVG icon="circle" width={6} fill={colors.white} left="75%" top="10%" />
                <SVG icon="upDown" hiddenMobile width={8} fill={colors.green} left="45%" top="10%" />
            </UpDownWide>
            <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
            <SVG icon="circle" width={12} fill={colors.pink} left="80%" top="60%" />
            <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
            <SVG icon="box" width={12} fill={colors.yellow} left="29%" top="26%" />
            <SVG icon="hexa" width={16} stroke={colors.red} left="75%" top="30%" />
            <SVG icon="hexa" width={8} stroke={colors.yellow} left="80%" top="70%" />
        </Divider>
    </>
)

export default Description
